// Импортируем другие js-файлы
$( document ).ready(function() {

    $("html[dir='rtl'] .home-story .link .btn-he").click(function(){
        if($(this).text() == "הסתר"){
            $(this).text("קרא עוד");
        }else{
            $(this).text("הסתר");
        }
    });

    $(".home-story .link .btn-en").click(function(){
        if($(this).text() == "Show less"){
            $(this).text("Read more");
        }else{
            $(this).text("Show less");
        }
    });

    // Memory modal

    // var triggerAtY = $('#our-leaders').offset().top - 70;
    // $(window).scroll(function(event) {
    //     if (triggerAtY > $(window).scrollTop()) {
    //         return;
    //     }
    //     $("html[dir='rtl'] .page-id-2182 #modal-memory").modal('show');
    //     $(this).off(event);
    // });


});